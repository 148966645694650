// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from 'firebase/functions';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCuY2IjLYdiSBJ8Bi8IWoKPLx07mzClwmo",
    authDomain: "nozzle-d3f06.firebaseapp.com",
    projectId: "nozzle-d3f06",
    storageBucket: "nozzle-d3f06.appspot.com",
    messagingSenderId: "314378690899",
    appId: "1:314378690899:web:fd37c23ceb927086d201a6",
    measurementId: "G-4VSJPJBMNL"
  };


// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// export const db = getDatabase(app);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);

export const db = getFirestore(app);

// const dbRef = ref_db(db);
export const functions = getFunctions(app);
export const createPaymentIntent = httpsCallable(functions, 'createPaymentIntent');
export const changeUserId = httpsCallable(functions, 'changeUserId');
export const getAccountLink = httpsCallable(functions, 'getAccountLink');
export const acceptCommentPayout = httpsCallable(functions, 'acceptCommentPayout');