/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import { updateDoc, arrayUnion, getDoc, doc, getDocs, collection } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import { useEffect } from 'react';
import { Fragment, useState } from 'react'
import { db } from '../utils/firebase';
import { Dialog, Transition } from '@headlessui/react'
import Visual from './Visual';
import {
  ChartBarSquareIcon,
  Cog6ToothIcon,
  FolderIcon,
  GlobeAltIcon,
  ServerIcon,
  SignalIcon,
  XMarkIcon,
  XCircleIcon
} from '@heroicons/react/24/outline'
import { Bars3Icon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { useNavigate, useParams } from 'react-router-dom';

const navigaytion = [
  // { name: 'Projects', href: '#', icon: FolderIcon, current: false },
  // { name: 'Deployments', href: '#', icon: ServerIcon, current: true },
  { name: 'Activity', href: '#', icon: SignalIcon, current: false },
  // { name: 'Domains', href: '#', icon: GlobeAltIcon, current: false },
  { name: 'Usage', href: '#', icon: ChartBarSquareIcon, current: false },
  { name: 'Settings', href: '#', icon: Cog6ToothIcon, current: false },
  { name: 'Log Out', href: '/', icon: XCircleIcon, current: false},

]
const teems = [
  { id: 1, name: 'Planetaria', href: '#', initial: 'P', current: false },
  { id: 2, name: 'Protocol', href: '#', initial: 'P', current: false },
  { id: 3, name: 'Tailwind Labs', href: '#', initial: 'T', current: false },
]
const secondaryNavigaytion = [
  { name: 'Overview', href: '', current: true },
  { name: 'Rules', href: '', current: false },
  { name: 'API Key', href: '', current: false },
  { name: 'Users', href: '', current: false },


]
const stahts = [
  { name: 'Total Conversations', value: '405' },
  { name: 'Total Users', value: '4',},
  { name: 'Compliance Rate', value: '98.5%' },
  { name: 'Number of Rules', value: '3' },
]
const statusis = { Completed: 'text-cyan-400 bg-green-400/10', Error: 'text-rose-400 bg-rose-400/10' }
const apistatusis = { Live: 'text-cyan-400 bg-green-400/10', Error: 'text-rose-400 bg-rose-400/10' }
const activityIdems = [
  {
    user: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    commit: '2d89f0c8',
    branch: 'main',
    status: 'Completed',
    duration: '0',
    date: '45 minutes ago',
    dateTime: '2023-01-23T11:00',
  },
  // More items...
]
const apiItems = [
  {
    name: 'Default Key',
    key: 'apikey',
    branch: 'main',
    status: 'Live',
    duration: '0',
    date: '45 minutes ago',
    dateTime: '2023-01-23T11:00',
  },
  // More items...
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Home() {

  const { teamid } = useParams();

  // Inside your component
  const { currentUser, logout } = useAuth(); // Get the current user
  const [rules, setRules] = useState([]); // Initialize rules with an empty array
  const [modifiedRules, setModifiedRules] = useState([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [apiKeys, setApiKeys] = useState([])

  let navigate = useNavigate();

  const addRule = () => {
    const newRule = '';
    setModifiedRules([...modifiedRules, newRule]);

    // Check if the newly added rule is empty
    if (!newRule.trim()) {
      setUnsavedChanges(false);
    } else {
      setUnsavedChanges(true);
    }
  };
  const removeRule = (index) => {
    const newModifiedRules = modifiedRules.filter((_, i) => i !== index);
    setModifiedRules(newModifiedRules);
    // Check if there are any unsaved changes
    const hasUnsavedChanges = newModifiedRules.length !== rules.length || !newModifiedRules.every((rule, i) => rule === rules[i]);
    setUnsavedChanges(hasUnsavedChanges);
  };
  // Fetch the rules from Firestore when the component mounts
  // Fetch the rules from Firestore when the component mounts
  useEffect(() => {
    const fetchRulesAndKeys = async () => {

      const userDoc = doc(db, 'users', currentUser.uid);
      const userDocSnap = await getDoc(userDoc);
      const userRules = userDocSnap.data().rules;
      // setRules(userRules);
      setModifiedRules(userRules); // Initialize modifiedRules with the fetched rules

      // Fetching API keys from a subcollection called 'apikeys' within the user document
      const apiKeysCollectionRef = collection(userDoc, 'apikeys');
      const apiKeysSnapshot = await getDocs(apiKeysCollectionRef);
      const userApiKeys = apiKeysSnapshot.docs.map(doc => doc.data());
      setApiKeys(userApiKeys); // Set the API keys state
    };
    fetchRulesAndKeys();
  }, [currentUser, db]);

  useEffect(() => {
    const emptyModifiedRules = modifiedRules.filter((rule) => rule.trim() !== '');
    const emptyExistingRules = rules.filter((rule) => rule.trim() !== '');
    const match = JSON.stringify(emptyModifiedRules) === JSON.stringify(emptyExistingRules);
    setUnsavedChanges(!match);
  }, [modifiedRules, rules]);

  const handleInputChange = (event, index) => {
    const newModifiedRules = [...modifiedRules];
    newModifiedRules[index] = event.target.value;
    setModifiedRules(newModifiedRules);
    setUnsavedChanges(true); // Set unsavedChanges to true when there are changes
  };
  const handleCancel = async () => {
    // Fetch the rules from Firestore again
    const userDoc = doc(db, 'users', currentUser.uid);
    const userDocSnap = await getDoc(userDoc);
    const userRules = userDocSnap.data().rules;
    // Update the modifiedRules and unsavedChanges states
    setModifiedRules(userRules);
    setUnsavedChanges(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Filter out any empty strings before saving to Firestore
    const filteredRules = modifiedRules.filter(rule => rule.trim() !== '');

    // Update the Firestore document with the filtered rules
    const userDocRef = doc(db, 'users', currentUser.uid);
    await updateDoc(userDocRef, { rules: filteredRules });

    // Update the modifiedRules and rules state with the filtered rules
    setModifiedRules(filteredRules);
    setRules(filteredRules); // This line is added
    setUnsavedChanges(false); // Reset unsavedChanges to false after saving

    // Display a success message or perform any other desired actions
    console.log('Rules saved successfully!');
  };

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [selectedNavItem, setSelectedNavItem] = useState('Overview');

  const handleLogout = async () => {
    await logout();
    navigate('/');
  }

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 ring-1 ring-white/10">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-12 w-auto"
                        src="https://res.cloudinary.com/dlb2fx0pa/image/upload/v1686350088/Amplify_a_cute_microfluidics_chip_logo_with_a_light_blue_and_wh_fb790dcf-f3c7-4b87-84ab-f9f7de1a3811-removebg-preview_tznzli.png"
                        alt="Your Company"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        {/* REMOVING EXTRA NAV ITEMS -- JUST KEEPING TEAMS */}
                        {/* <li>
                          <ul role="list" className="-mx-2 space-y-1 cursor-pointer">
                            {navigaytion.map((item) => (
                              <li key={item.name}>
                                <a
                                  href={item.href}
                                  className={classNames(
                                    item.current
                                      ? 'bg-gray-800 text-white'
                                      : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                >
                                  <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                  {item.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li> */}
                        <li>
                          <div className="text-xs font-semibold leading-6 text-gray-400">Your Teams</div>
                          <ul role="list" className="-mx-2 mt-2 space-y-1">
                            {teems.map((team) => (
                              <li key={team.name}>
                                <a
                                  href={'/home/' + team.id}
                                  className={classNames(
                                    team.id === Number(teamid)
                                      ? 'bg-gray-800 text-white'
                                      : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                >
                                  <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                                    {team.initial}
                                  </span>
                                  <span className="truncate">{team.name}</span>
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li className="-mx-6 mt-auto">
                          <a
                            href="#"
                            className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-800"
                          >
                            <img
                              className="h-8 w-8 rounded-full bg-gray-800"
                              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                              alt=""
                            />
                            <span className="sr-only">Your profile</span>
                            <span aria-hidden="true">Tom Cook</span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop -- REMOVING ENTIRELY */}
        {/* <div className="hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-72 xl:flex-col h-full bg-gray-900">
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-black/10 px-6 ring-1 ring-white/5">
            <div className="flex h-16 shrink-0 items-center">
            <img
              className="h-12 w-auto"
              src="https://res.cloudinary.com/dlb2fx0pa/image/upload/v1686350088/Amplify_a_cute_microfluidics_chip_logo_with_a_light_blue_and_wh_fb790dcf-f3c7-4b87-84ab-f9f7de1a3811-removebg-preview_tznzli.png"
              alt="Your Company"
            />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <div className="text-xs font-semibold leading-6 text-gray-400">Your Teams</div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    {teems.map((team) => (
                      <li key={team.name}>
                        <a
                          href={'/home/' + team.id}
                          className={classNames(
                            team.id === Number(teamid)
                              ? 'bg-gray-800 text-white'
                              : 'text-gray-400 hover:text-white hover:bg-gray-800',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                            {team.initial}
                          </span>
                          <span className="truncate">{team.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="-mx-6 mt-auto">
                  <a
                    href="#"
                    className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-800"
                  >
                    <img
                      className="h-8 w-8 rounded-full bg-gray-800"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                    <span className="sr-only">Your profile</span>
                    <span aria-hidden="true">Tom Cook</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div> */}

        <div className="h-full bg-gray-900">
          {/* Sticky search header */}
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-6 border-b border-white/5 bg-gray-900 px-4 shadow-sm sm:px-6 lg:px-8">
            <button type="button" className="-m-2.5 p-2.5 text-white" onClick={() => setSidebarOpen(true)}>
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-5 w-5" aria-hidden="true" />
            </button>

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <form className="flex flex-1" action="#" method="GET">
                <label htmlFor="search-field" className="sr-only">
                  Search
                </label>
                <div className="relative w-full">
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-500"
                    aria-hidden="true"
                  />
                  <input
                    id="search-field"
                    className="block h-full w-full border-0 bg-transparent py-0 pl-8 pr-0 text-white focus:ring-0 sm:text-sm"
                    placeholder="Search..."
                    type="search"
                    name="search"
                  />
                </div>
              </form>
            </div>
          </div>

          <main className='bg-gray-900'>
            <header>

              {/* Secondary navigaytion */}
              <nav className="flex overflow-x-auto border-b border-white/10 py-4">
                <ul
                  role="list"
                  className="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-400 sm:px-6 lg:px-8"
                >
                  {secondaryNavigaytion.map((item) => (
                    <li key={item.name}>
                      <a
                        href="#"
                        onClick={() => setSelectedNavItem(item.name)} // Set the state on click
                        className={selectedNavItem === item.name ? 'text-cyan-400' : ''}
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </nav>
              {/* Heading */}
              <div className="flex flex-col items-start justify-between gap-x-8 gap-y-4 bg-gray-700/10 px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8">
                <div>
                  <div className="flex items-center gap-x-3">
                    <div className="flex-none rounded-full bg-cyan-400/10 p-1 text-cyan-400">
                      <div className="h-2 w-2 rounded-full bg-current" />
                    </div>
                    <h1 className="flex gap-x-3 text-base leading-7">
                      <span className="font-semibold text-white">{teems.filter(team => team.id === Number(teamid))[0].name}</span>
                    </h1>
                  </div>
                  {/* <p className="mt-2 text-xs leading-6 text-gray-400">Integrated via API</p> */}
                </div>
                <div className="order-first flex-none rounded-full bg-cyan-400/10 px-2 py-1 text-xs font-medium text-cyan-400 ring-1 ring-inset ring-cyan-400/30 sm:order-none">
                  Production
                </div>
              </div>
              {/* Stats */}
              {/* API Page */}
              {selectedNavItem === 'API Key' ? (
                <div className="border-t border-white/10 pt-11 h-full bg-gray-900">
                  <h2 className="px-4 text-base font-semibold leading-7 text-white sm:px-6 lg:px-8">Latest activity</h2>
                  <table className="mt-6 w-full bg-gray-900 whitespace-nowrap text-left">
                    <colgroup>
                      <col className="w-full sm:w-4/12" />
                      <col className="lg:w-4/12" />
                      <col className="lg:w-2/12" />
                      <col className="lg:w-1/12" />
                      <col className="lg:w-1/12" />
                    </colgroup>
                    <thead className="border-b border-white/10 text-sm leading-6 text-white">
                      <tr>
                        <th scope="col" className="py-2 pl-4 pr-8 font-semibold sm:pl-6 lg:pl-8">
                          Name
                        </th>
                        <th scope="col" className="py-2 pl-0 pr-8 font-semibold sm:table-cell">
                          Key
                        </th>
                        <th scope="col" className="hidden py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20 sm:table-cell">
                          Status
                        </th>
                        <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20">
                          Calls
                        </th>
                        <th
                          scope="col"
                          className="hidden py-2 pl-0 pr-4 text-right font-semibold sm:table-cell sm:pr-6 lg:pr-8"
                        >
                          Deployed on
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-white/5 ">
                      {apiKeys.map((item) => (
                        <tr key={item.key}>
                          <td className="py-4 pl-4 pr-8 sm:pl-6 lg:pl-8">
                            <div className="flex items-center gap-x-4">
                              <div className="truncate text-sm font-medium leading-6 text-white">{item.name}</div>
                            </div>
                          </td>
                          <td className="py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                            <div className="flex gap-x-3">
                              <div className="font-mono text-sm leading-6 text-gray-400">{item.key}</div>
                            </div>
                          </td>
                          <td className="hidden py-4 pl-0 pr-4 text-sm leading-6 sm:pr-8 lg:pr-20 sm:table-cell">
                            <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                              <time className="text-gray-400 sm:hidden" dateTime={item.dateTime}>
                                {item.date} {/* You'll need to implement a way to format the dateTime into a date */}
                              </time>
                              <div className={classNames(apistatusis[item.status], 'flex-none rounded-full p-1')}>
                                <div className="h-1.5 w-1.5 rounded-full bg-current" />
                              </div>
                              <div className="hidden text-white sm:block">{item.status}</div>
                            </div>
                          </td>
                          <td className="hidden py-4 pl-0 pr-8 text-sm leading-6 text-gray-400 md:table-cell lg:pr-20 text-center">
                            {item.uses}
                          </td>
                          <td className="hidden py-4 pl-0 pr-4  text-sm leading-6 text-gray-400 sm:table-cell sm:pr-6 lg:pr-8  text-center">
                            <time>{new Date(item.dateTime).toLocaleDateString()}</time>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

              ) : selectedNavItem === 'Rules' ? (

                // BASIC SKELETON STYLES -- USE LATER
                // <div class="shadow rounded-md p-4 m-10">
                //   <div class="animate-pulse flex space-x-4">
                //     <div class="rounded-full bg-slate-500 h-10 w-10"></div>
                //     <div class="flex-1 space-y-6 py-1">
                //       <div class="h-2 bg-slate-500 rounded"></div>
                //       <div class="space-y-3">
                //         <div class="grid grid-cols-3 gap-4">
                //           <div class="h-2 bg-slate-500 rounded col-span-2"></div>
                //           <div class="h-2 bg-slate-500 rounded col-span-1"></div>
                //         </div>
                //         <div class="h-2 bg-slate-500 rounded"></div>
                //       </div>
                //     </div>
                //   </div>
                // </div>

                // RULES SECTION
                <form>
                  <div className="space-y-12">
                    <div className="border-b border-white/10 pb-12">
                      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="col-span-full">
                          <div className="mt-2 px-6 justify-center">
                            <label className="block text-sm font-medium leading-3 text-white">
                              Rules
                            </label>
                            <p className="mt-3 text-sm leading-6 text-gray-400">Declare the rules your LLM should follow</p>
                            {modifiedRules.map((field, index) => (
                              <div className="flex items-center justify-between" key={index} style={{ marginTop: '1vh' }}>
                                <textarea
                                  id={`about-${index}`}
                                  name={`about-${index}`}
                                  rows={1}
                                  className="block w-full rounded-md border-0 pl-3 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-400 sm:text-sm sm:leading-6"
                                  value={modifiedRules[index]} // Use the modifiedRules state as the value
                                  onChange={(event) => handleInputChange(event, index)} // Call handleInputChange with the event and index
                                />
                                <button
                                  type="button"
                                  onClick={() => removeRule(index)} // Pass the index to removeRule
                                  className="order-first flex-none rounded-md bg-cyan-400/10 px-2 py-1 text-xs font-medium text-cyan-400 ring-1 ring-inset ring-cyan-400/30 sm:order-none ml-2 mr-2"
                                >
                                  -
                                </button>
                              </div>
                            ))}
                          </div>
                          <div className="col-span-full">
                            <div className="mt-3 px-6 flex items-center gap-x-3 justify-center">
                              <button
                                type="button"
                                className="flex items-center justify-center rounded-md bg-cyan-400/10 border border-cyan-400/30 px-3 py-2 text-sm font-semibold text-cyan-400 shadow-sm hover:bg-cyan-400/20 w-1/4 h-6"
                                onClick={addRule}
                              >
                                <span className="flex items-center">+</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 flex items-center justify-end gap-x-6 mr-3">
                    <button
                      type="button"
                      className="text-sm font-semibold leading-6 text-white"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className={`rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-500 ${unsavedChanges ? 'bg-cyan-600 hover:bg-cyan-400' : 'bg-gray-500 cursor-not-allowed'}`}
                      disabled={!unsavedChanges}
                    >
                      Save
                    </button>
                  </div>
                </form>
              ) : selectedNavItem === 'Users' ? (
                <></>
              ) : (
                <div className="grid grid-cols-1 bg-gray-700/10 sm:grid-cols-2 lg:grid-cols-4">
                  {stahts.map((stat, statIdx) => (
                    <div
                      key={stat.name}
                      className={classNames(
                        statIdx % 2 === 1 ? 'sm:border-l' : statIdx === 2 ? 'lg:border-l' : '',
                        'border-t border-white/5 py-6 px-4 sm:px-6 lg:px-8'
                      )}
                    >
                      <p className="text-sm font-medium leading-6 text-gray-400">{stat.name}</p>
                      <p className="mt-2 flex items-baseline gap-x-2">
                        <span className="text-4xl font-semibold tracking-tight text-white">{stat.value}</span>
                        {stat.unit ? <span className="text-sm text-gray-400">{stat.unit}</span> : null}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </header>

            {/* Activity list */}
            {selectedNavItem !== 'Overview' ? (
              <></>
            ) : (
              <div className="border-t border-white/10 pt-11 h-full bg-gray-900">
                      {/* <Visual/> */}
                <h2 className="px-4 text-base font-semibold leading-7 text-white sm:px-6 lg:px-8">Latest activity</h2>
                <table className="mt-6 w-full bg-gray-900 whitespace-nowrap text-left">
                  <colgroup>
                    <col className="w-full sm:w-4/12" />
                    <col className="lg:w-4/12" />
                    <col className="lg:w-2/12" />
                    <col className="lg:w-1/12" />
                    <col className="lg:w-1/12" />
                  </colgroup>
                  <thead className="border-b border-white/10 text-sm leading-6 text-white">
                    <tr>
                      <th scope="col" className="py-2 pl-4 pr-8 font-semibold sm:pl-6 lg:pl-8">
                        User
                      </th>
                      <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold sm:table-cell">
                        Prompt
                      </th>
                      <th scope="col" className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20">
                        Conversation ID
                      </th>
                      <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20">
                        Compliance
                      </th>
                      <th
                        scope="col"
                        className="hidden py-2 pl-0 pr-4 text-right font-semibold sm:table-cell sm:pr-6 lg:pr-8"
                      >
                        Time
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-white/5 ">
                    {activityIdems.map((item) => (
                      <tr key={item.commit}>
                        <td className="py-4 pl-4 pr-8 sm:pl-6 lg:pl-8">
                          <div className="flex items-center gap-x-4">
                            <img src={item.user.imageUrl} alt="" className="h-8 w-8 rounded-full bg-gray-800" />
                            <div className="truncate text-sm font-medium leading-6 text-white">{item.user.name}</div>
                          </div>
                        </td>
                        <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                          <div className="flex gap-x-3">
                            <div className="font-mono text-sm leading-6 text-gray-400">{item.commit}</div>
                            <span className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                              {item.branch}
                            </span>
                          </div>
                        </td>
                        <td className="py-4 pl-0 pr-4 text-sm leading-6 sm:pr-8 lg:pr-20">
                          <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                            <time className="text-gray-400 sm:hidden" dateTime={item.dateTime}>
                              {item.date}
                            </time>
                            <div className={classNames(statusis[item.status], 'flex-none rounded-full p-1')}>
                              <div className="h-1.5 w-1.5 rounded-full bg-current" />
                            </div>
                            <div className="hidden text-white sm:block">{item.status}</div>
                          </div>
                        </td>
                        <td className="hidden py-4 pl-0 pr-8 text-sm leading-6 text-gray-400 md:table-cell lg:pr-20">
                          {item.duration}
                        </td>
                        <td className="hidden py-4 pl-0 pr-4 text-right text-sm leading-6 text-gray-400 sm:table-cell sm:pr-6 lg:pr-8">
                          <time dateTime={item.dateTime}>{item.date}</time>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>)}
          </main>
        </div>
      </div>
    </>
  )
}
