import Landing from "./components/Landing";
import { Route, Routes, Navigate } from 'react-router-dom'
import Signin from "./components/signin";
import Register from "./components/register";
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import { useAuth } from './contexts/AuthContext';
import Signout from "./components/signout";
import Home from "./components/Home";// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';

// TODO: Replace the following with your app's Firebase project configuratio
function App() {
  const { currentUser } = useAuth();
  console.log(currentUser)
  return (
      <Routes>
        {
          // Only show dashboard routes if logged in
          currentUser != null &&
          <>
            {/* if context found, skip sign-in and just take them to creatorDashboard */}
            {/* <Route path='/signin' element={<Navigate to='/dashboard' />}/>   */}
            <Route path='/home/:teamid' element={<Home />} />          
          </>
        }
        <Route path='/' element={<Landing />} />
        <Route path='/signin' element={<Signin />} />
        <Route path='/signout' element={<Signout />} />
        <Route path='/register' element={<Register />} />
        {/* <Route path='/home' element={<Navigate to='/signin' />}/>    */}
       
      </Routes>
  );
}
export default App;