import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import React, { useState } from 'react';

export default function Register() {
  const [username, setUsername] = useState('');
  const [firstname, setfirstname] = useState('');
  const [lastname, setlastname] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');  // State for confirmed password
  const { signup } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {  // Check if password and confirmed password match
      alert("Your passwords do not match.");
      return;
    }

    signup(username, password, firstname, lastname).then(value => {
      navigate('/home');
    }, (error) => {
      alert("Looks like that username password combo is invalid.\n" + error);
    });
  }
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);  // Function to handle confirmed password change
  }

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  }
  const handlefirstChange = (event) => {
    setfirstname(event.target.value);
  }
  const handlelastChange = (event) => {
    setlastname(event.target.value);
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  }

  return (
    <div style={{ backgroundColor: 'white', height: '100vh' }} className="flex min-h-full flex-1 flex-col">
      <div style={{ marginTop: '10vh' }}>

        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-20 w-auto"
            src="https://res.cloudinary.com/dlb2fx0pa/image/upload/v1686084468/Amplify_a_cute_microfluidics_chip_logo_with_a_light_blue_and_wh_4644b32f-d910-4404-84a8-5a9b61164a39_dwdsdy.png"
            alt="Your Company"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Register your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm mr-10 ml-10">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div className="flex space-x-6">
              <div className="w-1/2">
                <label htmlFor="first" className="block text-sm font-medium leading-6 text-gray-900">
                  First Name
                </label>
                <div className="mt-2">
                  <input
                    id="first"
                    name="first"
                    type="text"
                    required
                    className="block w-full pl-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"
                    value={firstname}
                    onChange={handlefirstChange}
                  />
                </div>
              </div>
              <div className="w-1/2">
                <label htmlFor="last" className="block text-sm font-medium leading-6 text-gray-900">
                  Last Name
                </label>
                <div className="mt-2">
                  <input
                    id="last"
                    name="last"
                    type="text"
                    required
                    className="block w-full pl-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"
                    value={lastname}
                    onChange={handlelastChange}
                  />
                </div>
              </div>
            </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="block w-full pl-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"
                  value={username}
                  onChange={handleUsernameChange}
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  Password
                </label>

              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="block w-full pl-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </div>

            </div>
            <div> <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                Confirm Password
              </label>
              <div className="text-sm">

              </div>
            </div>
              <div className="mt-2">
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="block w-full pl-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"
                  value={confirmPassword}  // Change value to confirmPassword
                  onChange={handleConfirmPasswordChange}  // Change onChange to handleConfirmPasswordChange
                />
              </div></div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-cyan-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600">
                Register
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Already a member?{' '}
            <a href="/signin" className="font-semibold leading-6 text-cyan-500 hover:text-cyan-500">
              Sign In
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
