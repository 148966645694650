import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Route, Routes, Navigate } from 'react-router-dom'

const navigation = [
    { name: 'Product', href: '#' },
    { name: 'Pricing', href: '#' },
    { name: 'Contact', href: '#' },
]

export default function Landing() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <div style={{ height: '100vh' }}>
            <header className="absolute inset-x-0 top-0 z-50">
                <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                    <div className="flex lg:flex-1" >
                        <a href="/" className="-m-1.5 p-1.5">
                            <span className="sr-only">Your Company</span>
                            <img
                                className="h-20 w-auto"
                                src="https://res.cloudinary.com/dlb2fx0pa/image/upload/v1686084468/Amplify_a_cute_microfluidics_chip_logo_with_a_light_blue_and_wh_4644b32f-d910-4404-84a8-5a9b61164a39_dwdsdy.png"
                                alt=""
                            />
                        </a>
                    </div>
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-black-400"
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="hidden lg:flex lg:gap-x-12">
                        {navigation.map((item) => (
                            <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-black">
                                {item.name}
                            </a>
                        ))}
                    </div>
                    <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                        <a href="/" className="text-sm font-semibold leading-6 text-white">
                            Log in <span aria-hidden="true">&rarr;</span>
                        </a>
                    </div>
                </nav>
                <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                    <div className="fixed inset-0 z-50" />
                    <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
                        <div className="flex items-center justify-between">
                            <a href="/" className="-m-1.5 p-1.5">
                                <span className="sr-only">Your Company</span>
                                <img
                                    className="h-12 w-auto"
                                    src="https://res.cloudinary.com/dlb2fx0pa/image/upload/v1686350088/Amplify_a_cute_microfluidics_chip_logo_with_a_light_blue_and_wh_fb790dcf-f3c7-4b87-84ab-f9f7de1a3811-removebg-preview_tznzli.png"
                                    alt=""
                                />
                            </a>
                            <button
                                type="button"
                                className="-m-2.5 rounded-md p-2.5 text-gray-400"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/25">
                                <div className="space-y-2 py-6">
                                    {navigation.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-800"
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                                <div className="py-6">
                                    <a
                                        href="/"
                                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-gray-800"
                                    >
                                        Log in
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Dialog.Panel>
                </Dialog>
            </header>

            <div style={{ borderWidth: '3px', borderStyle: 'solid', height: '100vh' }} className="relative isolate overflow-hidden pt-14">
                <div className="pt-20"></div>
                <div className="pt-10"></div>

                <img
                    src="https://res.cloudinary.com/dlb2fx0pa/image/upload/v1686086687/Untitled_design_shjbiz.png"
                    alt=""
                    className="absolute inset-0 -z-10 h-full w-full max-w-full object-cover"
                    style={{ objectPosition: '68% 50%' }}
                />


                <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
                    <div className="flex justify-start">
                        <div className='flex flex-col'>
                            <h1 className="text-4xl font-bold tracking-tight text-black sm:text-6xl pt-4 sm:pt-0">
                                Rule your LLMs
                            </h1>
                            <p className="mt-6 text-lg leading-8 text-black-300">
                                Compliance, Safety, and Correctness over AI toolkits
                            </p>
                            <div className="mt-10 flex items-left justify-left gap-x-6">
                                <a
                                    href="/register"
                                    className="rounded-md bg-cyan-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-500"
                                >
                                    Get started
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
