import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const RadialBarChart = () => {
  const ref = useRef();

  const width = 900;
  const height = 900;
  const innerRadius = 0.35 * width / 2;
  const outerRadius = 0.9 * width / 2;
  const data =
    [[0.0, 0.0, 0.01, 0.03, 0.99], [0.04, 0.03, 0.02, 0.0, 0.0], [0.0, 0.01, 0.03, 0.16, 0.79], [0.22, 0.08, 0.02, 0.01, 0.0], [0.0, 0.01, 0.01, 0.56, 0.79], [0.08, 0.02, 0.01, 0.01, 0.0], [0.0, 0.0, 0.0, 0.0, 0.0], [0.07, 0.07, 0.01, 0.01, 0.01]]
    ;

  const processedData = data.flatMap((entry, i) =>
    entry.map((value, j) => ({
      date: `${i + 1}/1/2018, 4:00:00 PM`,
      index: j,
      subArrayIndex: i,
      value: 1 - value
    }))
  );

  const xScale = d3.scaleBand()
    .domain(processedData.map((d) => d.date))
    .range([0, 2 * Math.PI]);

  const yDomain = [
    d3.min(processedData, (d) => d.value),
    d3.max(processedData, (d) => d.value)
  ];

  const yScale = d3.scaleLinear()
    .domain(yDomain)
    .range([innerRadius, outerRadius]);

  const arc = d3.arc()
  .innerRadius((d) => yScale(0))
  .outerRadius((d) => yScale(d.value))
  .startAngle((d) => xScale(d.date) + d.index * xScale.bandwidth() / data[0].length)
  .endAngle((d) => xScale(d.date) + (d.index + 1) * xScale.bandwidth() / data[0].length)
  .padAngle(0.01)
  .padRadius(innerRadius)
  .cornerRadius(100); // Add this line

  // New code: create color scale
  const colorScale = d3.scaleSequential()
    .domain([0, data.length])
    .interpolator(d3.interpolateCool);
  
    useEffect(() => {
    const svg = d3.select(ref.current)
      .attr('width', width)
      .attr('height', height);

    const container = svg.append('g')
      .attr('class', 'container')
      .attr('transform', `translate(${width / 2},${height / 2})`)
      .style('font-size', 10)
      .style('font-family', 'sans-serif');

      container.selectAll('path')
      .data(processedData)
      .join('path')
      .attr('d', arc)
      .style('fill', d => colorScale(d.subArrayIndex)) // Use color scale to fill bars
      // .style('opacity', d => 1 - d.value); // Adjust opacity based on value


    // New code: create line generator
    const line = d3.lineRadial()
      .curve(d3.curveCardinalClosed); // This creates a closed, smooth line

    // Calculate mid-points of wedges
    const lineData = processedData.map(d => [
      xScale(d.date) + (d.index + 0.5) * xScale.bandwidth() / data[0].length, // Angle
      yScale(d.value) // Radius
    ]);

    // Add line to SVG
    container.append('path')
      .datum(lineData)
      .attr('d', line)
      .attr('fill', 'none')
      .attr('stroke', 'red')
      .attr('stroke-width', 2);

    return () => {
      container.selectAll('path').remove();
    };
  }, []);

  return <svg ref={ref}></svg>;
};

export default RadialBarChart;