import { createUserWithEmailAndPassword, onAuthStateChanged, sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import React, {
  useContext,
  useState,
  useEffect
} from 'react';
import { auth } from '../utils/firebase'
import { collection, addDoc, setDoc, doc, getDoc } from "firebase/firestore";
import { db } from '../utils/firebase';


const AuthContext = React.createContext();
// This is just a wrapper so it looks cleaner when we use the auth context
export function useAuth() {
  return useContext(AuthContext);
}

// This is the actual provider for the auth context.
export function AuthProvider({ children }) {

  const [currentUser, setCurrentUser] = useState();

  // Generate a random API key
  function generateApiKey() {
    return [...Array(30)].map(() => Math.random().toString(36)[2]).join('');
  }
  // Function to get current date-time in the desired format
  function getCurrentDateTime() {
    return new Date().toISOString();
  }
  function signup(email, password, first, last) {
    return createUserWithEmailAndPassword(auth, email, password).then((u) => {
      console.log(u.user.uid)
      const docRef = setDoc(doc(db, "users", u.user.uid), {
        email: email,
        first: first,
        last: last,
        numruns: 0,
        runs: [],
        rules: [],
      });
      // API keys collection setup
      const apiKeysCollectionRef = collection(db, "users", u.user.uid, "apikeys");
      const apiKeys = [{
        name: 'Default API Key',
        key: generateApiKey(),
        branch: 'main',
        status: 'Live',
        uses: 0,
        dateTime: getCurrentDateTime(),
      }, {
        name: 'ChatGPT Extension',
        key: generateApiKey(),
        branch: 'main',
        status: 'Live',
        uses: 0,
        dateTime: getCurrentDateTime(),
      }];

      // Add each API key to the collection
      apiKeys.forEach(apiKey => {
        addDoc(apiKeysCollectionRef, apiKey);
      });

      console.log("created");
      // const stripeStuffCollectionRef = collection(db, "users", u.user.uid, "stripestuff");
      // const stripeStuffDocRef = doc(stripeStuffCollectionRef, "stripestuff");
      // const stripeStuffData = {
      //   someField: "someValue",
      // };
      // setDoc(stripeStuffDocRef, stripeStuffData);
    });


  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    return signOut(auth);
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  function updateEmail(email) {
    return updateEmail(auth.currentUser, email); // Not sure that these are right
  }

  function updatePassword(password) {
    return updatePassword(auth.currentUser, password); // Not sure that these are right
  }


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setCurrentUser(user);
    })

    return unsubscribe;
  }, [])

  const value = {
    currentUser,
    setCurrentUser,
    signup,
    login,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}
